<div class="flex flex-col py-0.5 sm:px-1">
  <div class="flex justify-between items-center group" (click)="clickToActive(index)"
    [ngClass]="{'category_item_div_active' : active, 'category_item_div':!active}">
    <div class="flex items-center space-x-2">
      <span class="pi pi-{{icon}} text-[{{iconColor}}]"></span>
      <span [ngStyle]="{'color': textColor}" class="flex justify-end group-hover:text-white">{{menuName}}</span>
    </div>
    <span class="text-[{{iconColor}}] opacity-50 group-hover:text-white">
      {{menuProp}}
    </span>
  </div>
</div>