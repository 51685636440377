<app-auth-layout>
  <!-- Form -->
    <div class="flex flex-col items-center w-full">
      <form [formGroup]="registerForm" (submit)="registerUser()">
      <div class="flex flex-col md:w-[100%] items-center">
        <h1 class="mb-2 text-2xl font-bold md:text-3xl md:mb-4">Create an Account</h1>
        <span class="text-sm text-center text-gray-500 md:text-base">Create your account and make orders and payments seamlessly</span>
      </div>
      <app-text-input-field 
        [formSubmitted]="registerFormSubmitted"
        ngDefaultControl 
        label="Full Name" 
        inputId="registername" 
        placeHolder="Enter Your Full Name"
        [formControl]="registerForm.controls.name"
      ></app-text-input-field>
      <app-text-input-field 
        [formSubmitted]="registerFormSubmitted"
        ngDefaultControl 
        label="Email Address" 
        inputId="email" 
        placeHolder="Enter your Email"
        [formControl]="registerForm.controls.email"
      ></app-text-input-field>
      <app-password-field 
        [submitted]="registerFormSubmitted"
        ngDefaultControl 
        inputId="password2" 
        [formControl]="registerForm.controls.password"
        (passwordFocus)="showPasswordRequirements = true"
        (passwordBlur)="showPasswordRequirements = false"
      ></app-password-field>
      
      <!-- Password requirements -->
      @if (showPasswordRequirements) {
        <div class="mt-2 text-sm text-gray-600">
          <p>Password must:</p>
          <ul class="list-disc list-inside">
            <li [class.text-green-500]="passwordMeetsLengthRequirement">Be at least 8 characters long</li>
            <li [class.text-green-500]="passwordContainsNumber">Contain at least one number</li>
            <li [class.text-green-500]="passwordContainsSpecialChar">Contain at least one special character</li>
          </ul>
        </div>
      }

      <app-text-input-field 
        [formSubmitted]="registerFormSubmitted"
        ngDefaultControl 
        label="Referral ID (Optional)" 
        inputId="Referral" 
        placeHolder="Enter your Referral ID"
        [formControl]="registerForm.controls.referral"
      ></app-text-input-field>
      <div class="form_div">
        <button class="create_button bg-[#0F172A] text-white" type="submit">
          Create your Account
        </button>
      </div>
      <!-- <div class="mt-3">Or</div> -->
      <div class="items-center form_div">
        <div id="googleSignInDiv"></div>
      </div>
      <div class="flex flex-col items-center w-full mt-6">
        <div class="flex flex-row justify-center space-x-1">
          <span class="text-gray-500 font-[400]">
            Already have an account ?
          </span>
          <a
            routerLink="/login"
            routerLinkActive="active"
            class="hover:underline font-[500] hover:cursor-pointer"
            >Sign in</a
          >
        </div>
      </div>
  </form>
    </div>
</app-auth-layout>
