import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthLayoutComponent } from '@components/layouts';
import { AuthService, LoadingService } from '@data-access/services';
import { ResetPasswordDTO, ResponseDTO, User } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { PasswordFieldComponent } from "../../shared/password-field/password-field.component";
import { first } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
  imports: [AuthLayoutComponent, PasswordFieldComponent, FormsModule, RouterLink, ReactiveFormsModule]
})
export class ResetPasswordComponent implements OnInit {

  code: string = '';
  user: string = '';

  resetPasswordForm = new FormGroup({
    email: new FormControl(),
    code: new FormControl(),
    password: new FormControl(),
  });

  constructor(private authService: AuthService, private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute,
    private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.isLoading.next(true);
    this.route.params.pipe(first()).subscribe(params => {
      this.user = params['user'];
      this.code = params['code'];
    });
    this.authService.GetUserById(this.user).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const data = result.data as User;
          console.log(data);  
          this.resetPasswordForm.patchValue({ email: data.email });
        }
        else {
          this.toastr.error(result.message);
        }
        this.loadingService.isLoading.next(false);
      }
    });
  }


  resetPassword() {
    this.loadingService.isLoading.next(true);
    const formValue = this.resetPasswordForm.value;
    const resetData: ResetPasswordDTO = {
      email: formValue.email,
      password: formValue.password,
      code: this.code
    };
    this.authService.resetPassword(resetData).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.toastr.success(result.message);
          this.router.navigate(['login']);
        }
        else {
          this.toastr.error(result.message);
        }
        this.loadingService.isLoading.next(false);
      },
      error: () => {
        this.toastr.error("Something went wrong", "Something went wrong");
        this.loadingService.isLoading.next(false);
      }
    });
  }
}
