import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService, InventoryService, LoadingService, LocalService, OrderService, OutletService } from '@data-access/services';
import { CartHolder, CreateOrder, Inventory, InventoryByCategory, Outlet, ProductCategory, ResponseDTO, User } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject, first, takeUntil } from 'rxjs';
import { SideMenuItemComponent } from "../side-menu-item/side-menu-item.component";
import { DialogModule } from 'primeng/dialog';
import { InfoComponent } from "../info/info.component";
import { BadgeModule } from 'primeng/badge';
import { PaymentPercentage } from '@utils/index';
import { ConfirmDialogService } from '../../data-access/services/confirm-dialog/confirm-dialog.service';
import { CartCardComponent } from '../shared/cart-card/cart-card.component';
import { InfoOverlayComponent } from "../shared/info-overlay.component";
import { formatCurrency } from '../../utils/shared/shared';
@Component({
  selector: 'app-order',
  standalone: true,
  templateUrl: './order.component.html',
  styleUrl: './order.component.css',
  imports: [SideMenuItemComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    OverlayPanelModule, DialogModule, InfoComponent, BadgeModule, CartCardComponent, InfoOverlayComponent]
})
export class OrderComponent implements OnInit, OnDestroy {
  cartDialog: boolean = false;
  deliveryLocation = 'General';
  deliveryLocationDialog: boolean = false;
  updateProfileDialog: boolean = false;
  outlet: Outlet | undefined = undefined;
  categories: ProductCategory[] = [];
  allproducts: Inventory[] = [];
  cart: Inventory[][] = [];
  cartCount: number = 0;
  deliveryFee: number = 0;
  ordersCost: number = 0;
  totalCost: number = 0;
  totalCostString: string = '';
  currentCategory = -1;
  selectedDeliveryMode: number = 0;
  selectedDeliveryLocation: number = 0;
  serviceCharge: number = 0;
  baseServiceCharge: number = 150;
  user: User | null = null;
  //currentOrder: number = 0;
  activeCategory: number = 0;
  confirmDeliveryInfo: boolean = false;
  searchQuery: string = '';
  filteredCategories: ProductCategory[] = [];
  filteredProducts: Inventory[] = [];

  overlayTitle: string = '';
  overlayDescription: string = '';

  constructor(private toastr: ToastrService,
    private orderService: OrderService,
    private outletService: OutletService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private authService: AuthService,
    private inventoryService: InventoryService,
    private local: LocalService,
    private confirmDialogService: ConfirmDialogService) {
  }

  private $destroy = new Subject<void>();

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.fetchOutlet(params['slug']);
    });

    this.authService.user$.pipe(takeUntil(this.$destroy)).subscribe({
      next: (result) => {
        this.user = result as User;
        this.profileUpdateForm.patchValue({ phone: this.user?.phone, address: this.user?.address, email: this.user?.email });
      },
      error: () => {
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  formatCurrency = formatCurrency;

  newOrder() {
    if (this.cartDialog) {
      this.cartDialog = false;
    }
    if (this.cart[this.cart.length - 1]?.length) {
      this.cart.push([]);
      this.toastr.info('Starting a new order', 'New Order');
    }
    else {
      this.toastr.info('You already started a new order', 'New Order');
    }

  }

  profileUpdateForm = new FormGroup({
    address: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
    acceptTerms: new FormControl(false, Validators.requiredTrue)
  });


  sortOutExistingCart() {
    const existingCart = this.local.getData("cart");
    if (existingCart !== null && existingCart !== undefined) {
      const savedCart: CartHolder = JSON.parse(existingCart);
      if ((!this.isOlderThanTenMinutes(savedCart.timestamp) && savedCart.outletId === this.outlet?.id)) {
        if (savedCart.userId === this.user?.id) {
          this.cart = savedCart.cart;
          const flattenedOrders = this.cart.flat();
          this.cartCount = flattenedOrders.length;
          // Extract the 'price' property from each object and sum them up
          this.ordersCost = flattenedOrders.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.price;
          }, 0);
          this.calculateTotalCost();
        }
      }
      else {
        this.local.removeData("cart");
      }
    }
  }

  isOlderThanTenMinutes(millisToCheck: number): boolean {
    const THIRTY_MINUTES = 30 * 60 * 1000;
    const now = new Date().getTime();
    const thirtyMinutesAgo = now - THIRTY_MINUTES;
    return millisToCheck < thirtyMinutesAgo;
  }

  showDialog() {
    this.cartDialog = !this.cartDialog;
  }

  saveCartToLocalStorage(): void {
    const now = new Date().getTime();
    const saveCart: CartHolder = {
      cart: this.cart,
      outletId: this.outlet?.id as number,
      userId: this.user?.id as string,
      timestamp: now
    }
    this.local.saveData("cart", JSON.stringify(saveCart));
  }

  updateProfile() {
    this.loadingService.isLoading.next(true);
    const formValue = this.profileUpdateForm.value;

    if (!this.user?.email && !formValue.email) {
      this.loadingService.isLoading.next(false);
      this.toastr.warning("Email is required");
      return;
    }

    if (!formValue.address || !formValue.phone) {
      this.loadingService.isLoading.next(false);
      this.toastr.warning("Address and phone are required");
      return;
    }

    this.loadingService.isLoading.next(false);
    this.toggleProfileModal();
    this.confirmDeliveryInfo = true;

    if (this.user) {
      const updatedFields: Partial<User> = {};

      if (this.user.address !== formValue.address) {
        updatedFields.address = formValue.address;
      }
      if (this.user.phone !== formValue.phone) {
        updatedFields.phone = formValue.phone;
      }
      if (Object.keys(updatedFields).length > 0) {
        this.user = { ...this.user, ...updatedFields };
        this.authService.setUser(this.user);
      }
    }

    this.checkout();
  }

  fetchReferralGifts(userId: string) {
    this.authService.getEligibleReferralPackages(userId).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const gifts = result.data as string[];
          gifts.forEach(element => {
            this.toastr.success(element);
          });
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

  fetchOutlet(outletSlug: string) {
    this.outletService.getOutletBySlug(outletSlug).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.outlet = result.data as Outlet;
          this.fetchInventoryByCategory(this.outlet.id);
          if (this.outlet?.delivery) {
            this.deliveryFee += this.outlet?.deliveryFee as number;
          }
          this.sortOutExistingCart();
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (ex) => {
        console.log(ex);
        console.log("Something went wrong");
      }
    });
  }

  fetchInventoryByCategory(outlet: number) {
    this.inventoryService.getInventoryByCategoryByOutlet(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          console.log(result.data);
          const inventory = result.data as InventoryByCategory[];
          this.allproducts = inventory.flatMap(x => x.inventories);
          this.categories = inventory.map(x => ({
            name: x.productCategory,
            id: x.productCategoryId,
            quantity: x.inventoryQuantity
          } as ProductCategory));
          this.initializeProductsCategories();
        }
      }
    });
  }

  initializeProductsCategories() {
    this.filteredCategories = [];
    this.filteredProducts = [];
    this.filteredCategories.push({ name: 'All', id: 0, quantity: this.allproducts.length });
    this.filteredCategories.push(...this.categories);
    this.filteredProducts = this.allproducts;
  }

  validateAddressAndPhone(): boolean {
    if (!this.user?.phone && !this.profileUpdateForm.value.phone) {
      return false;
    }
    return true;
  }

  deliveryLoctionChanged(index: number): void {
    if (this.outlet) {
      const location = this.outlet?.deliveryLocations[index];
      this.totalCost += -this.deliveryFee;
      this.deliveryLocation = location.location;
      const now = new Date().getHours();
      this.outlet.deliveryFee = now >= 18 && location?.nightAmount !== 0
        ? location?.nightAmount
        : location?.dayAmount;
      this.deliveryModeChanged(1);
    }
  }

  toggleDeliveryLocationModal() {
    this.deliveryLocationDialog = !this.deliveryLocationDialog;
  }

  deliveryModeChanged(selectedDeliveryMode: number): void {
    // console.log("Delivery Mode Changed: ", selectedDeliveryMode);
    const deliveryFeeChange = selectedDeliveryMode === 1 ? (this.outlet?.deliveryFee as number) : -this.deliveryFee;
    this.totalCost += deliveryFeeChange;
    this.deliveryFee = selectedDeliveryMode === 1 ? deliveryFeeChange : 0;
    this.selectedDeliveryMode = selectedDeliveryMode;
    if (this.selectedDeliveryMode === 1 && this.outlet && this.outlet.deliveryLocations.length > 0) {
      this.toggleDeliveryLocationModal();
    }
    this.updateServiceCharge();
  }

  categoryProducts(event: Event, productCategoryId: number) {
    event.preventDefault();
    if (productCategoryId === this.activeCategory) return;
    this.activeCategory = productCategoryId;
    this.currentCategory = productCategoryId;
    if (productCategoryId === 0) {
      this.filteredProducts = this.allproducts;
    }
    else {
      this.filteredProducts = this.allproducts.filter(x => x.productCategoryId === productCategoryId);
    }
  }

  updateServiceCharge() {
    const gateway = PaymentPercentage.SquadPay;
    const gatewayCharge = (this.ordersCost * (gateway / 100));
    const vendBlocCharge = (this.ordersCost * (PaymentPercentage.VendBloc / 100));
    let serviceCharge = gatewayCharge + vendBlocCharge;
    if (this.ordersCost > 2000 && gateway == PaymentPercentage.Paystack) {
      serviceCharge = serviceCharge + 100;
    }
    this.serviceCharge = Math.round(serviceCharge);
  }

  clearCart() {
    this.confirmDialogService.open({
      title: 'Clear Cart',
      message: 'Are you sure you want to clear your cart?',
      confirmLabel: 'Clear',
      cancelLabel: 'Cancel'
    }).then((result) => {
      if (result) {
        this.cart = [];
        this.cartCount = 0;
        this.ordersCost = 0;
        this.deliveryModeChanged(0);
        this.calculateTotalCost();
      }
    });

  }

  calculateTotalCost() {
    this.updateServiceCharge();
    this.totalCost = this.ordersCost + this.serviceCharge;
    if (this.selectedDeliveryMode === 1) {
      this.totalCost += this.deliveryFee;
    }
  }

  addToCart(product: Inventory) {
    if (product.orderQuantity < 1) {
      this.toastr.warning("Please enter a valid quantity");
      return;
    }
    const price = product.salesPrice * product.orderQuantity;
    product.price = price;
    this.ordersCost = this.ordersCost + (product.price);
    this.calculateTotalCost();
    if (!this.cart.length) {
      this.cart.push([]);
    }
    if (this.cart.length) {
      const currentCart: Inventory[] = this.cart[this.cart.length - 1];
      const isProductInCurrentCart = currentCart.findIndex(x => x.productId === product.productId);
      if (isProductInCurrentCart < 0) {
        currentCart.push({ ...product, group: this.cart.length });
        this.cartCount++;
      }
      else {
        currentCart[isProductInCurrentCart].orderQuantity += product.orderQuantity;
        currentCart[isProductInCurrentCart].price += (product.price);
      }
    }
    product.orderQuantity = 1;
    this.saveCartToLocalStorage()
    this.toastr.success('Added To Cart', 'Success');
  }

  updateProductQuantity(productId: number, increment: boolean) {
    const index = this.filteredProducts.findIndex(x => x.productId === productId);
    if (increment) {
      this.filteredProducts[index].orderQuantity = this.filteredProducts[index].orderQuantity + 1;
      this.filteredProducts[index].orderPrice = formatCurrency(this.filteredProducts[index].orderQuantity* this.filteredProducts[index].price, 'NGN')
    }
    else {
      if (this.filteredProducts[index].orderQuantity > 1) {
        this.filteredProducts[index].orderQuantity = this.filteredProducts[index].orderQuantity - 1;
        this.filteredProducts[index].orderPrice = formatCurrency(this.filteredProducts[index].orderQuantity* this.filteredProducts[index].price, 'NGN')
      }
    }
    this.calculateTotalCost();
  }

  removeFromCart(event: { cartItem: Inventory, index: number }) {
    this.cartCount--;
    this.ordersCost = this.ordersCost - event.cartItem.price;
    const currentCart: Inventory[] = this.cart[event.index];
    const itemIndex = currentCart.findIndex(x => x.productId === event.cartItem.productId);
    if (itemIndex !== -1) {
      currentCart.splice(itemIndex, 1);
      if (currentCart.length < 1) {
        this.cart.splice(event.index, 1);
      }
    }
    this.saveCartToLocalStorage();
    this.calculateTotalCost();
  }

  updateCartQuantity(event: { cartItem: Inventory, increment: boolean; }) {
    const orderGroup = event?.cartItem?.group ?? 0;
    const targetOrder: Inventory[] = this.cart[orderGroup - 1];
    const isProductInTargetOrder = targetOrder.findIndex(x => x.productId === event?.cartItem?.productId);
    if (isProductInTargetOrder < 0) {
      this.toastr.info('Product not found');
    }
    else {
      if (event.cartItem.orderQuantity < 2 && event.increment === false) return;
      if (event?.increment) {
        targetOrder[isProductInTargetOrder].orderQuantity++;
        targetOrder[isProductInTargetOrder].price += (event.cartItem.salesPrice);
        this.totalCost += (event.cartItem.salesPrice);
        this.ordersCost += (event.cartItem.salesPrice);
      }
      else {
        targetOrder[isProductInTargetOrder].orderQuantity--;
        targetOrder[isProductInTargetOrder].price -= (event.cartItem.salesPrice);
        this.totalCost -= (event.cartItem.salesPrice);
        this.ordersCost -= (event.cartItem.salesPrice);
      }
    }
    this.saveCartToLocalStorage();
    this.calculateTotalCost();
  }

  toggleProfileModal() {
    this.updateProfileDialog = !this.updateProfileDialog;
    if (this.updateProfileDialog) {
      this.cartDialog = false;
    }
  }

  checkout() {
    const email = this.user?.email as string;
    if (!email) {
      if (this.profileUpdateForm.value.email === email) {
        this.toastr.warning("Please ensure your email, phone and address are correct");
        this.toggleProfileModal();
        return;
      }
    }
    if (this.cartCount < 1) {
      this.toastr.warning("Add Items to cart Before Checking Out");
      return;
    }
    if (!this.validateAddressAndPhone()) {
      this.toastr.warning("Please update your phone and address");
      this.toggleProfileModal();
      return;
    }
    else {
      if (!this.confirmDeliveryInfo) {
        this.toastr.warning("Please confirm your phone and address");
        this.toggleProfileModal();
        this.confirmDeliveryInfo = true;
        return;
      }
    }
    this.loadingService.isLoading.next(true);
    const order: CreateOrder = {
      orders: this.cart,
      outletId: this.outlet?.id ?? 0,
      customer: {
        email: this.user?.email || this.profileUpdateForm.value.email,
        address: this.user?.address || this.profileUpdateForm.value.address,
        phone: this.user?.phone || this.profileUpdateForm.value.phone
      },
      amount: this.totalCost,
      deliveryCost: this.selectedDeliveryMode === 0 ? 0 : this.deliveryFee,
      orderCost: this.ordersCost,
      serviceCharge: this.serviceCharge,
    };
    this.orderService.checkout(order).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.loadingService.isLoading.next(false);
          this.toastr.success('Trying to set up your payment link', 'Order Created');
          this.local.removeData("cart");
          window.location.href = result.data as string;
        }
        else {
          this.toastr.error(result.message);
          this.loadingService.isLoading.next(false);
        }
      },
      error: () => {
        this.loadingService.isLoading.next(false);
      }
    });
  }

  onPhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');
  }

  onSearch() {
    const query = this.searchQuery.toLowerCase().trim();

    if (query === '') {
      this.initializeProductsCategories();
    } else {
      this.filteredProducts = this.allproducts.filter(product =>
        product.product.toLowerCase().includes(query)
      );
      this.filteredCategories = this.categories.filter(category =>
        category.name.toLowerCase().includes(query)
      )
      if (this.filteredProducts.length < 1 && this.filteredCategories.length > 0) {
        this.filteredCategories = this.filteredCategories.map(category => ({
          ...category,
          quantity: this.allproducts.filter(p => p.productCategoryId === category.id).length
        }));
      }
      else {
        const matchingCategoryIds = new Set(this.filteredProducts.map(p => p.productCategoryId));
        this.filteredCategories = this.categories.filter(category =>
          matchingCategoryIds.has(category.id)
        ).map(category => ({
          ...category,
          quantity: this.filteredProducts.filter(p => p.productCategoryId === category.id).length
        }));
      }

    }
    this.activeCategory = this.filteredCategories.some(c => c.id === this.activeCategory)
      ? this.activeCategory
      : -1;
  }

  // Add this method to handle manual quantity changes
  onQuantityChange(newQuantity: number | string, productId: number) {
    // Convert empty string to 0 or handle invalid input
    const quantity = newQuantity === '' ? 0 : Number(newQuantity);

    // Ensure quantity is not negative and is a valid number
    if (isNaN(quantity) || quantity < 0) {
      newQuantity = 0;
    }

    const product = this.filteredProducts.find(p => p.productId === productId);
    if (product) {
      product.orderQuantity = quantity;
    }
  }
}