<app-outlet>
    <app-table [cols]="tableCols" [enableAdd]="true" (addButtonPressed)="showDialog()"
        (removeButtonPressed)="deleteProduct($event)" [tableData]="products" [enableEdit]="true" (editButtonPressed)="showEditDialog($event)" tableName="Products"></app-table>


    <app-dialog [(visible)]="productDialog" header="Add Product" width="30%" [showFooter]=true (confirm)="saveProduct()"
        (cancel)="showDialog()">
        <form [formGroup]="createProductForm" (submit)="saveProduct()">
            <app-text-input-field  [formSubmitted]="formSubmitted" [formControl]="createProductForm.controls.name"  ngDefaultControl inputType="text" label="Products Name"
            inputId="name" placeHolder="Enter product name"
            ></app-text-input-field>
            <div class="form_div">
                <label for="">Category</label>
                <div class="rounded-md border">
                    <p-dropdown 
                        [options]="productCategories" 
                        formControlName="productCategory"
                        placeholder="Select a Category" 
                        optionLabel="name" 
                        inputId="categoryId-label"
                    ></p-dropdown>
                </div>
            </div>
            <div class="form_div">
                <label for="">Type</label>
                <div class="rounded-md border">
                    <p-dropdown 
                        [options]="productTypes" 
                        formControlName="productType"
                        placeholder="Select a Type" 
                        optionLabel="name" 
                        inputId="productType-label"
                    ></p-dropdown>
                </div>
            </div>
            <div class="form_div">
                <label for="">Unit of Measurement</label>
                <div class="rounded-md border">
                    <p-dropdown 
                        [options]="units" 
                        formControlName="unit"
                        placeholder="Select a Unit" 
                        optionLabel="name" 
                        inputId="unit-label"
                        [style]="{'width': '100%'}"
                        [appendTo]="'body'"
                        [scrollHeight]="'200px'"
                    ></p-dropdown>
                </div>
            </div>
        </form>
    </app-dialog>

    <app-dialog [(visible)]="editDialog" header="Edit {{selectedProduct?.name}}" width="30%" [showFooter]=true (confirm)="updateProduct()"
    (cancel)="closeEditDialog()">
    <form [formGroup]="editProductForm">
        <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.name"  ngDefaultControl inputType="text" label="Name"
        inputId="edit_name"
        ></app-text-input-field>
        <app-text-input-field [formSubmitted]="formSubmitted" [formControl]="editProductForm.controls.description"  ngDefaultControl inputType="text" label="Description"
        inputId="edit_description"
        ></app-text-input-field>
        <div class="form_div">
            <label for="">Category</label>
            <div class="rounded-md border">
                <p-dropdown 
                    [options]="productCategories"    
                    formControlName="productCategory"
                    placeholder="Select a Category" 
                    optionLabel="name" 
                    inputId="categoryId-label"
                ></p-dropdown>
            </div>
        </div>
        <div class="form_div">
            <label for="">Type</label>
            <div class="rounded-md border">
                <p-dropdown 
                    [options]="productTypes" 
                    formControlName="productType"
                    placeholder="Select a Type" 
                    optionLabel="name" 
                    inputId="productType-label"
                ></p-dropdown>
            </div>
        </div>
        <div class="form_div">
            <label for="">Unit of Measurement</label>
            <div class="rounded-md border">
                <p-dropdown 
                    [options]="units" 
                    formControlName="unit"
                    placeholder="Select a Unit" 
                    optionLabel="name" 
                    inputId="unit-label"
                    [style]="{'width': '100%'}"
                    [appendTo]="'body'"
                    [scrollHeight]="'200px'"
                ></p-dropdown>
            </div>
        </div>
    </form>
</app-dialog>

</app-outlet>