<app-outlet>

    <p-card header="Report">
        <form>
            <div class="flex flex-col md:flex-row justify-between lg:pr-10">
                <div class="flex flex-col md:flex-row space-x-2 mb-2 w-full lg:w-[50%] justify-around">
                    <div class="flex w-[50%] md:w-full flex-col space-y-2 ">
                        <label for="">Report Type</label>
                        <p-dropdown class="border rounded-lg w-auto custom-dropdown" [options]="reports" optionLabel="name" showClear="true"
                            placeholder="Select a Type"></p-dropdown>
                    </div>
                    <div class="flex w-[30%] md:w-auto flex-col space-y-2">
                        <label for="">Date</label>
                        <p-calendar class="py-3 px-2 border rounded-lg custom-calendar" (onSelect)="dateChanged($event)" [(ngModel)]="date" [showIcon]="true" inputId="templatedisplay">
                        </p-calendar>
                    </div>
                </div>
                <div class="w-1/3 md:w-auto">
                    <div class="flex flex-col space-y-4 justify-end">
                        <label class="" for="">Total Cost</label>
                        <span class="font-bold text-center justify-center">{{total }}</span>
                    </div>
                </div>
            </div>
        </form>

        <app-table [cols]="tableCols" [enableShow]="true" (showButtonPressed)="showDialog($event)"
        [tableData]="orders" tableName="Orders"></app-table>
    </p-card>

</app-outlet>