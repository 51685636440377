import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-info-overlay',
  standalone: true,
  imports: [CommonModule, OverlayPanelModule],
  template: `
    <span class="cursor-pointer pi pi-info-circle opacity-45" 
          (mouseenter)="showOverlay($event)" 
          (mouseleave)="hideOverlay()">
          
    </span>
    <p-overlayPanel #op>
        <div class="p-4 w-[300px]">
            <h3 class="mb-2 text-lg font-semibold">{{title}}</h3>
            <div class="space-y-2">
                <p class="text-gray-600">{{content}}</p>
            </div>
        </div>
    </p-overlayPanel>
  `,
  styles: [`
    :host ::ng-deep .p-overlaypanel {
      @apply shadow-lg rounded-lg;
    }
    
    :host ::ng-deep .p-overlaypanel .p-overlaypanel-content {
      @apply p-0;
    }
  `]
})
export class InfoOverlayComponent {
  @Input() title: string = 'Information';
  @Input() content: string = '';
  
  @ViewChild('op') overlayPanel!: OverlayPanel;

  showOverlay(event: MouseEvent) {
    this.overlayPanel.show(event);
  }

  hideOverlay() {
    this.overlayPanel.hide();
  }
}