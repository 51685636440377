<app-outlet>

    <p-card header="Settings">
        <form [formGroup]="updateOutletForm" (submit)="updateOutlet()">
            <div>
               
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <label for="">Name</label>
                        <input class="p-3 rounded-md border" formControlName="name" type="text">
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <label for="">Phone</label>
                        <input class="p-3 rounded-md border" type="text" formControlName="phone">
                    </div>
                </div>
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <label for="">Address</label>
                        <input class="p-3 rounded-md border" type="text" formControlName="address">
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <label for="">Delivery Fee</label>
                        <input pInputText pKeyFilter="money" class="p-3 rounded-md border"
                            formControlName="deliveryFee" />
                    </div>
                </div>
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-row justify-between w-[100%]">
                        <p-checkbox class="p-2 custom-checkbox" label="Online Order" [binary]="true" formControlName="onlineOrder" />
                        <p-checkbox class="p-2 custom-checkbox" [binary]="true" formControlName="onlineDelivery" label="Delivery" />
                    </div>
                </div>
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-row justify-between w-[100%]">
                        <p-checkbox class="p-2 custom-checkbox" label="Less than zero inventory" [binary]="true" formControlName="lessThanZeroInvetory" />
                        <p-checkbox class="p-2 custom-checkbox" label="Private Delivery Riders" [binary]="true" formControlName="inHouseDispatchersOnly" />
                    </div>
                </div>
                
                <div class="flex justify-between items-end">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue"
                        (click)="toggleDeliveryLocationDialog()" type="button">Manage Delivery Location</button>
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue" type="submit">Update</button>
                </div>
            </div>
        </form>

       
    </p-card>

    <p-card header="Outlet Image" class="mt-4">
        <div class="">
            <p-avatar label={{outlet?.name[0]}} styleClass="mr-2" size="xlarge" shape="circle" />
            <p-fileUpload name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php"
                (onUpload)="onUpload($event)" [multiple]="false" accept="image/*" maxFileSize="500000" />
        </div>
    </p-card>

    <p-dialog [(visible)]="manageDeliveryLocationDialog" [draggable]="false" [breakpoints]="{ '1000px': '90vw' }" [style]="{ width: '50vw' }" header="Delivery Locations"
        [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="m-0 space-y-2 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                <div class="flex flex-col space-y-3">
                    @if(this.outlet){
                    <form [formGroup]="createDeliveryLocationForm" (submit)="createOutletDeliveryLocation()">
                        <div class="flex flex-col">
                            <app-text-input-field ngDefaultControl label="" inputId="location"
                                placeHolder="Enter Location"
                                [formControl]="createDeliveryLocationForm.controls.location"></app-text-input-field>
                            <div class="flex mb-2 space-x-3">
                                <div class="w-1/2">
                                    <app-text-input-field ngDefaultControl label="" inputId="dayAmount"
                                        placeHolder="Enter Day Amount"
                                        [formControl]="createDeliveryLocationForm.controls.dayAmount"></app-text-input-field>
                                </div>
                                <div class="w-1/2">
                                    <app-text-input-field ngDefaultControl label="" inputId="nightAmount"
                                        placeHolder="Enter Night Amount"
                                        [formControl]="createDeliveryLocationForm.controls.nightAmount"></app-text-input-field>
                                </div>
                            </div>
                            <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue"
                                type="submit">Create</button>
                        </div>
                    </form>
                    <div class="flex justify-between">
                        <span class="font-bold">Location </span>
                        <span class="font-bold">Day </span>
                        <span class="font-bold">Night </span>
                        <span class="font-bold"> </span>
                    </div>
                    @for (location of this.outlet.deliveryLocations; track $index) {
                    <div class="flex justify-between space-y-2">
                        <span >{{$index + 1}}. {{location.location}} </span>
                        <span >
                            {{location.nightAmount}} </span>
                        <span >
                            {{location.nightAmount}} </span>
                        <span><p-button (click)="deleteLocation(location.id)" severity="danger"
                                icon="pi pi-trash"></p-button>
                        </span>
                    </div>
                    }
                    }
                </div>
            </div>
        </ng-template>
    </p-dialog>
</app-outlet>
