import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@data-access/services';
import { map } from 'rxjs/operators';

export const authGuard = (route: any) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAuthenticated$.pipe(
    map(isAuthenticated => {
      console.log(isAuthenticated);
      const url = route.routeConfig?.path || '';
      console.log('Auth Guard Check - URL:', url);
      console.log('Is Authenticated:', isAuthenticated, 'Type:', typeof isAuthenticated);
      if (!isAuthenticated && !url.includes('login')) {
        router.navigate(['/login']);
        // toastr.warning("Please login to proceed to this page");
        return false;
        //return 
      }
      return true;
    })
  );
};
