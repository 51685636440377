import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TableComponent } from '@components/table/table.component';
import { OutletService, ProductService } from '@data-access/services';
import { CreateProduct, Outlet, Product, ProductCategory, ProductType, ResponseDTO, Unit, UpdateProduct } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { Subject, first, takeUntil } from 'rxjs';
import { OutletComponent } from '../outlet/outlet.component';
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AppDialogComponent } from "../../shared/app-dialog/app-dialog.component";
import { TextInputFieldComponent } from '../../shared/text-input-field/text-input-field.component';

@Component({
  selector: 'app-outlet-products',
  standalone: true,
  templateUrl: './outlet-products.component.html',
  styleUrl: './outlet-products.component.css',
  imports: [OutletComponent, CardModule, CardModule, TableModule, ButtonModule, DialogModule,
    ToolbarModule, FileUploadModule, InputNumberModule, DropdownModule, InputTextModule, RippleModule,
    ReactiveFormsModule, ConfirmPopupModule, ToastModule, TableComponent, AppDialogComponent, TextInputFieldComponent]
})
export class OutletProductsComponent implements OnDestroy {

  products: Product[] = [];
  productTypes: ProductType[] = [{ id: 0, name: "Perishable" }, { id: 1, name: "Non-Perishable" }];
  productType: number = 0;
  outlet: Outlet|undefined = undefined;
  units: Unit[] = [];
  productCategories: ProductCategory[] = [];
  productDialog: boolean = false;
  editDialog: boolean = false;
  selectedProduct: Product|undefined = undefined;
  tableCols: PrimeTableColumn[] = [
    { field: 'name', header: 'Name' },
    { field: 'productCategory', header: 'Category' },
    { field: 'unit', header: 'Unit' },
    { field: 'productType', header: 'Type' }];
    private $destroy = new Subject<void>();
    formSubmitted : boolean = false;

  constructor(private outletService: OutletService, private confirmationService: ConfirmationService, private messageService: MessageService, private productService: ProductService, private toastr: ToastrService) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      this.outlet = result as Outlet;
    });
    this.fetchProducts(this.outlet?.id ?? 0);
    this.fetchProductCategories(this.outlet?.id ?? 0);
    this.fetchUnits(this.outlet?.storeId ?? 0);
  }

  showDialog() {
    this.productDialog = !this.productDialog;
    if(!this.productDialog){
      this.createProductForm.reset();
    }
  }

  closeEditDialog() {
    this.editDialog = false;
    this.editProductForm.reset();
  }

  showEditDialog($event:number) {
    this.editDialog = !this.editDialog;
    if(!this.editDialog){
      this.editProductForm.reset();
    }
    else{
      const product = this.products[$event];
      console.log(product)
      if(product){
        this.selectedProduct = product;
        const selectedCategory = this.productCategories.find(cat => cat.id === product.productCategoryId) || null;
        const selectedUnit = this.units.find(unit => unit.id === product.unitId) || null;
        const selectedType = this.productTypes.find((types) => types?.name === product.productType) || null;

        this.editProductForm.patchValue({
          name: product.name,
          description: product.description,
          productCategory: selectedCategory,
          unit: selectedUnit,
          productType: selectedType
        });
      }
    }
  }

  createProductForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl(''),
    productCategory: new FormControl(),
    unit: new FormControl(),
    productType: new FormControl()
  });

  editProductForm = new FormGroup({
    name: new FormControl(),
    description: new FormControl(),
    productCategory: new FormControl(),
    unit: new FormControl(),
    productType: new FormControl()
  });

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  saveProduct() {
   this.formSubmitted = true;
    const formData = this.createProductForm.value;
    const data: CreateProduct = {
      name: formData.name as string,
      description: '',
      storeId: this.outlet?.storeId ?? 0,
      unitId: formData?.unit?.id,
      productCategoryId: formData.productCategory.id,
      productType: formData.productType.id
    };
    this.productService.createProduct(data).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.createProductForm.reset();
          this.toastr.success(response.message);
          const newProduct = response.data as Product;
          this.products.push(newProduct);
          this.showDialog();
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  updateProduct() {
   this.formSubmitted = true;
    const formData = this.editProductForm.value;
    const data: UpdateProduct = {
      id: this.selectedProduct?.id ?? 0,
      name: formData.name ?? '',
      description: formData.description ?? '',
      storeId: this.outlet?.storeId ?? 0,
      unitId: formData?.unit?.id,
      productCategoryId: formData.productCategory.id,
      productType: formData.productType.id
    };
    this.productService.updateProduct(data).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          const productIndex = this.products.findIndex(x => x.id === data.id);
          if (productIndex !== -1) {
            console.log(this.products[productIndex]);
            this.products[productIndex] = { ...response.data as Product };
            console.log(this.products[productIndex]);
          }
          this.closeEditDialog();
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  deleteProductAPI(id: number) {
    this.productService.deleteProduct(id).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.products = this.products.filter(x => x.id !== id);
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error("Something went wrong");
      },
    });
  }

  deleteProduct(eventAndId: { event: Event, id: number; }) {
    const event: Event = eventAndId.event;
    const id: number = eventAndId.id;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to delete this record?',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      accept: () => {
        this.deleteProductAPI(id);
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }

  public fetchProducts(outlet: number) {
    if(outlet === 0)return;
    this.outletService.getOutletProducts(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
         this.products = result.data as Product[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (err) => {
        console.log("Something went wrong");
        console.log(err);
      }
    });
  }

  public fetchProductCategories(outlet: number) {
    if(outlet === 0)return;
    this.outletService.getOutletProductCategories(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.productCategories = result.data as ProductCategory[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

  public fetchUnits(store: number) {
    if(store === 0)return;
    this.outletService.getOutletUnits(store).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.units = result.data as Unit[];
        }
        else {
          console.log("else something went wrong");
        }
      },
      error: () => {
        console.log("error Something went wrong");
      }
    });
  }
}
