import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { first } from 'rxjs';
import { AuthService, LoadingService } from '@data-access/services';
import { InfoComponent } from '@components/info/info.component';
import { AuthLayoutComponent, HeaderComponent } from '@components/layouts';
import { LoginResponseDTO, RegisterUser, ResponseDTO } from '@models/index';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { PasswordFieldComponent } from '@components/shared/password-field/password-field.component';
import { TextInputFieldComponent } from '@components/shared/text-input-field/text-input-field.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-register',
  standalone: true,
  templateUrl: './register.component.html',
  styleUrl: './register.component.css',
  imports: [RouterOutlet,
    RouterLink, HeaderComponent, ReactiveFormsModule, AuthLayoutComponent, InfoComponent, PasswordFieldComponent,
    TextInputFieldComponent]
})
export class RegisterComponent implements OnInit {
  registerFormSubmitted: boolean = false;
  showPasswordRequirements: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private router: Router,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id:environment.googleOauthKey,
        callback: this.googleLogin.bind(this),
        auto_select:false,
        cancel_on_tap_outside:true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("googleSignInDiv"),{theme:"outline", size:"large", width:"100%", logo_alignment:"center"}
      );
      // @ts-ignore
      google.accounts.id.prompt((notification:PromptMomentNotification) => {});
    }
  }

  registerForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)
    ]),
    referral: new FormControl('')
  });

  get passwordMeetsLengthRequirement(): boolean {
    const password = this.registerForm.get('password')?.value ?? '';
    return password.length >= 8;
  }

  get passwordContainsNumber(): boolean {
    const password = this.registerForm.get('password')?.value ?? '';
    return /\d/.test(password);
  }

  get passwordContainsSpecialChar(): boolean {
    const password = this.registerForm.get('password')?.value ?? '';
    return /[!@#$%^&*]/.test(password);
  }

  async googleLogin(response: CredentialResponse) {
    this.authService.loginGoogleUser(response).pipe(first()).subscribe({
      next:(result:ResponseDTO) => {
        if(result.status){
          this.authService.saveUserDetails(result.data as LoginResponseDTO);
        }
      },
      complete: () => {
        const isAuthenticated: boolean = this.authService.isUserAuthenticated();
        if (isAuthenticated) {   
          this.zone.run(() => {
            this.loadingService.isLoading.next(false);
            this.toastr.success("Sign up Successful", "Successful Operation");
            this.router.navigate(['/']);
        });

        }else{
          this.zone.run(() => {
            this.toastr.error("Unable to sign you up", "Something went wrong");
          });
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", "Something went wrong");
        this.loadingService.isLoading.next(false);
      }
    })
  }

  registerUser() {
    this.registerFormSubmitted = true;
    if (!this.registerForm.valid) {
      return;
    }
    this.loadingService.isLoading.next(true);
    const formvalue = this.registerForm?.value;
    const userData: RegisterUser = {
      name: formvalue?.name ?? '',
      email: formvalue?.email ?? '',
      password: formvalue?.password ?? '',
      referralId: formvalue?.referral ?? ''
    };
    this.authService.registerUser(userData).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.toastr.success(result.message, 'Proceed to your email for verification');
          this.router.navigate(['login']);
        } else {
          this.toastr.error(result.message);
        }
        this.loadingService.isLoading.next(false);
      },
      error: () => {
        this.toastr.error("Something went wrong", "Invalid Operation");
        this.loadingService.isLoading.next(false);
      }
    });
  }
}
