<app-outlet>

  <div class="mb-4">
    <p-panel header="Search">
      <div class="flex flex-col md:flex-row">
        <div class="flex w-full md:w-[45%] 2xl:w-[70%] space-x-2">
          <div class="w-[80%]">
            <input [(ngModel)]="searchQuery"
              class="px-1 py-2 pl-2 w-full rounded-md border-2 md:pl-6 focus:outline-none placeholder:text-sm md:placeholder:text-base"
              type="text" placeholder="Order ID" />
          </div>
          <div class="w-auto">
            <button (click)="searchOrder()"
              class="border px-[18px]  rounded-md border-gray-500 text-center text-[17px] text-[400] py-2 flex justify-center space-x-2 bg-[#0F172A] text-white hover:bg-[#FAFAFA] hover:text-[#0F172A] hover:shadow-lg duration-300"
              type="submit">
              <span>Search</span>
            </button>
          </div>
          <div>

          </div>
        </div>

      </div>

    </p-panel>
  </div>


  <app-table [cols]="tableCols" [enableEdit]="false" [enableShow]="true" (showButtonPressed)="showDialog($event)"
    [tableData]="orders" tableName="Orders"></app-table>

    <app-dialog
    [(visible)]="dialogVisible"
    header="Order Invoice"
    width="60%"
    [showFooter]=false
    (confirm)="onConfirmAction()"
    (cancel)="onCancelAction()"
  >
      <div class="flex flex-col mt-2 space-y-3">
        <div class="relative h-10 w-72 md:w-[35%]">
          <select #statuses (change)="statusChanged(statuses.value)"
            class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2 text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900">
            @for (item of status; track $index) {
              <option class="px-2 py-2 space-y-2" [selected]="item.name === currentOrder?.status" value="{{$index}}">{{item.name}}</option>
            }         
          </select>
          <label
            class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight">
            Update Status
          </label>
        </div>
        <div class="flex justify-between">
          <span>Date : {{currentOrder?.formattedDate}}</span>
          <span>Store : {{currentOrder?.store }}-({{currentOrder?.outlet}})</span>
        </div>
        <div class="flex justify-between">
          <span>Payment Status : {{currentOrder?.status}}</span>
          <span>Id : {{currentOrder?.id}}</span>
        </div>
        <div class="flex justify-between">
          <span>Customer Name: {{currentOrder?.customer?.name }}</span>
          <span>Customer Phone: {{currentOrder?.customer?.phone}}</span>
        </div>
        @for (item of createRange(currentOrder?.totalOrder); track $index) {
        <div class="flex justify-center">
          <span class="text-sm">Order {{$index + 1}}</span>
        </div>
  
        <div>
          <p-table [value]="currentOrder?.products" [tableStyle]="{ 'min-width': '30rem' }">
            <ng-template pTemplate="header">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
              @if(product.group === $index+1){
              <tr>
                <td>{{rowIndex + 1 }}</td>
                <td>{{ product.product }}</td>
                <td>{{ product.amount }}</td>
                <td>{{ product.orderQuantity }}</td>
                <td>{{ product.orderQuantity * product.amount}}</td>
              </tr>
              }
            </ng-template>
          </p-table>
  
        </div>
        }
        <div class="flex justify-between">
          <span>Delivery Cost : {{currentOrder?.deliveryCost}}</span>
          <span>Order Cost : {{currentOrder?.amount}}</span>
        </div>
        <div class="flex justify-between">
          <span>Total Cost : {{currentOrder?.currency}} {{currentOrder?.deliveryCost + currentOrder?.amount}}</span>
        </div>
      </div>
  </app-dialog> 

</app-outlet>