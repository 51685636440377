import { Component, NgZone, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthLayoutComponent } from '@components/layouts';
import { AuthService, LoadingService } from '@data-access/services';
import { LoginDTO, LoginResponseDTO, ResponseDTO } from '@models/index';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { PasswordFieldComponent } from "../../shared/password-field/password-field.component";
import { TextInputFieldComponent } from '@components/shared/text-input-field/text-input-field.component';
import { environment } from '@environments/environment';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    AuthLayoutComponent, PasswordFieldComponent,TextInputFieldComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  ngOnInit(): void {
    // Only execute window-dependent code in browser environment
    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      window.onGoogleLibraryLoad = () => {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: environment.googleOauthKey,
          callback: this.googleLogin.bind(this),
          auto_select: false,
          cancel_on_tap_outside: true
        });
        // @ts-ignore
        google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById("googleSignInDiv"), { theme: "outline", size: "large", width: "100%", logo_alignment: "center" }
        );
        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => { });
      }
    }
  }

  loginFormSubmitted : boolean = false;

  constructor(private authService: AuthService, private toastr: ToastrService,
    private router: Router,
    private loadingService: LoadingService, private zone: NgZone,private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  loginForm = this.fb.group({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
  });

  async googleLogin(response: CredentialResponse) {
    this.authService.loginGoogleUser(response).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.authService.saveUserDetails(result.data as LoginResponseDTO);
        }
      },
      complete: () => {
        const isAuthenticated: boolean = this.authService.isUserAuthenticated();
        if (isAuthenticated) {
          this.zone.run(() => {
            this.loadingService.isLoading.next(false);
            this.toastr.success("Login Successful", "Successful Operation");
            this.router.navigate(['/']);
          });

        } else {
          this.zone.run(() => {
            this.toastr.error("Unable to sign you in", "Something went wrong");
          });
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", "Something went wrong");
        this.loadingService.isLoading.next(false);
      }
    })
  }

login() {
  this.loginFormSubmitted = true;
  //this.loginForm.markAllAsTouched();


  if (this.loginForm.valid) {
    this.authService.logOut();
    this.loadingService.isLoading.next(true);
    const formValue = this.loginForm.value;
    const loginData: LoginDTO = {
      email: formValue.email as string,
      password: formValue.password as string
    };
    this.authService.loginUser(loginData).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.authService.saveUserDetails(result.data as LoginResponseDTO);
          this.authService.setAuthenticated(true);
        }
        else {
          this.toastr.error(result.message);
        }
        this.loadingService.isLoading.next(false);
      },
      complete: () => {
        const isAuthenticated: boolean = this.authService.isUserAuthenticated();
        if (isAuthenticated) {
          this.loadingService.isLoading.next(false);
          this.toastr.success("Login Successful", "Successful Operation");
          this.router.navigate(['/']);
        } else {
          this.toastr.error("Unable to sign you in", "Something went wrong");
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", "Something went wrong");
        this.loadingService.isLoading.next(false);
      }
      });
    }
  }
}
