<app-admin-layout>
    <app-breadcrumb 
      [items]="breadcrumbItems"
      [home]="homeItem"
    ></app-breadcrumb>
    <app-table [cols]="tableCols" [enableOpen]="true" (openButtonPressed)="navigateToOutlet($event)"
    [enableAdd]="enableAdd" (addButtonPressed)="showDialog()" [tableData]="outlets" tableName="Outlets"></app-table>

    <p-dialog header="Add Outlet" [(visible)]="visible" position="top" [modal]="true" [style]="{ width: '50vw' }"
        [draggable]="false" [resizable]="false">
        <form [formGroup]="createOutletForm" (submit)="createOutlet()">
            <div class="flex flex-col mt-5 space-y-2 w-full">
                <div class="form_div">
                    <label for="">Store's Name</label>
                    <input class="p-3 rounded-md border" placeholder="Store Name" formControlName="storeId" type="text"
                        required />
                </div>
                <div class="form_div">
                    <label for="">Outlet's Name</label>
                    <input class="p-3 rounded-md border" placeholder="Outlet Name" formControlName="name" type="text"
                        required />
                </div>
                <div class="form_div">
                    <label for="">Select Outlet's Currency</label>
                    <p-dropdown class="" [options]="currencies" formControlName="currency" placeholder="Select a Currency" optionLabel="name" inputId="float-label"></p-dropdown>       
                  </div>
                  <div class="flex justify-end items-end w-full">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue" type="submit">Create</button>
                  </div>
            </div>
        </form>
    </p-dialog>
</app-admin-layout>