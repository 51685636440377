import { CommonModule, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-menu-item.component.html',
  styleUrl: './side-menu-item.component.css'
})
export class SideMenuItemComponent {
  @Input() menuName : string = '';
  @Input() menuProp : string | null= '';
  @Input() link : string = '';
  @Input() index : number = 0;
  @Input() active : boolean = false;
  @Input() icon : string = '';
  @Input() textColor : string = '';
  @Input() iconColor : string = '';


  constructor(private router: Router){}

  navigate(){
    if(this.link !== ''){
      this.router.navigate([this.link])
    }
  }

  clickToActive(index:number){
    this.active = !this.active; 
  }

}
